<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-name"
              label="Название"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              id="input-group-route"
              label="Путь"
              label-for="input-route"
            >
              <b-form-input
                id="input-route"
                v-model.trim="f.marketing_name"
                placeholder="Путь"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
export default {
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        name: this.$route.query?.name,
        route: this.$route.query?.route,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
